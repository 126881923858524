nav {
    background-color: rgb(255, 221, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin-right: 20px;
  }
  
  li:last-child {
    margin-right: 0;
  }
  
  a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 10px;
    display: inline-block;
  }
  
  a:hover {
    background-color: #444;
  }
  