.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgb(255, 221, 0);
  color: #000;
  padding: 20px;

}

.footer-left img {
  max-width: 100px;
  padding-left: 20px;
}

.footer-center {
  text-align: center;
  margin: 0 auto;
  width: 50%;
}


.footer-right ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.footer-right li {
  margin-right: 10px ;
}

.footer-right a {
  color: #000;
  font-size: small;
  text-decoration: none;
  padding: 20px;

}

  