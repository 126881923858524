.nosotros-container {
    padding: 5rem;
    background-color: gold;
    text-align: center;
  }
  
  .nosotros-container h2 {
    margin-bottom: 2rem;
    

  }
  
  .nosotros-container p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }
  
  .nosotros-container ul {
    list-style: none;
    margin-bottom: 3rem;
    text-align: center;
  }
  
  .nosotros-container ul li {
    margin-bottom: 1rem;
  }
  
  .nosotros-container button {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .nosotros-container button:hover {
    background-color: #555;
    /* make round edges */
  }
  
  .contact-btn {
    background-color: rgb(255, 221, 0);
    padding: 1rem 2rem;

    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;    color: black;
  }
  