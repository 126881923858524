body {
  font-family: 'Josefin Sans', sans-serif;
}

.hero-container {
    height: 400px;
    width: 100vw;
    max-width: 100vw;
    /* set the default font to be Century Gothic */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('/src/images/hero-bg.jpg');
    background-size: cover;
    color: #fff;
    text-align: center;
  }
  
  .hero-container h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .hero-container p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .hero-container button {
    padding: 1rem 2rem;
    background-color: #fff;
    color: #333;
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .hero-container button:hover {
    background-color: #333;
    color: #fff;
  }
  