.product-grid {
  padding: 2rem;
  background-color: #fff;
  text-align: center;
}

.product-grid h2 {
  margin-bottom: 2rem;
  padding-top: 20px;
}

.header-title {
  margin-top: 20px;
}

.product-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.product-item {
  margin: 20px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  width: 300px; /* Set a fixed width for each item */
}

.product-item:hover {
  transform: scale(1.1);
}

.product-item img {
  width: 100%;
  height: auto;
}

.product-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-details h3 {
  padding: 10px;
  text-align: center;
}

.product-details p {
  padding: 10px;
  text-align: center;
}

.product-item:hover .product-details {
  opacity: 1;
}

.product-details h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.product-details p {
  font-size: 0.9rem;
  line-height: 1.2rem;
}
