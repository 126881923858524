.image-gallery-container {
    background-color: rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-image {
    width: 30rem;
    margin-bottom: 1rem;
}

.main-image img {
    width: 100%;
    height: auto;
}

.small-images {
    display: flex;
    justify-content: center;
}

.small-image {
    width: 15rem;
    margin: 0 0.5rem;
}

.small-image img {
    width: 100%;
    height: auto;
}